import React from "react";
import PropTypes from "prop-types";
import View from "components/messages/SubmittedForm.view";

function SubmittedForm(props) {
  const type = props.match.params.type
  const formId = props.match.params.form_id

  return <View 
    type={type}
    formId={formId}
  />;
}

SubmittedForm.propTypes = {};

export default SubmittedForm;