import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Form from "components/form/Form";
import Login from "components/auth/Login";
import LoginAuth from "components/auth/LoginAuth";
import Logout from "components/auth/Logout";
import SubmittedForm from "components/messages/SubmittedForm";
import Admin from "components/form/Admin";

const App = () =>
  <div id="app">
    <BrowserRouter>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/form/:form_id" component={Form} />
        <Route path="/login" component={Login} />
        <Route path="/auth" component={LoginAuth} />
        <Route path="/logout" component={Logout} />
        <Route path="/submitted_form/:type/:form_id" component={SubmittedForm} />
        <Redirect to="/form/1" />
      </Switch>
    </BrowserRouter>
  </div>;

App.propTypes = {};

export default App;