/* eslint-disable max-len */
import React from "react";
import { Formik, Field, Form } from "formik";
import PropTypes from "prop-types";
import { Modal, ModalRoute, ScriptTag } from "seed/helpers";
import { BrowserRouter, Route } from "react-router-dom";
import Share from "./Share";

const getField = (field, multipleData, setMultipleData, setFieldValue, parentName = "", depth = 0, idx = 0) => {

  const fieldCount = multipleData[parentName + field.name] ?? 1

  if (field.type == "SECTION") {
    return (
      <a class="section-title collapsed" data-toggle="collapse" href={"#section-" + field.settings.index}>
        <div class="w-100 text-center py-2 px-3 mb-5 mt-7 font-weight-bold text-uppercase" style={{ backgroundColor: "#eaf7f3" }}>
          <label style={{ fontSize: "0.95rem", cursor: "pointer" }}><span class="mr-2">{field.settings.index}.</span>{field.settings.title}<i class="fas ml-2 section-icon"></i></label>
        </div>
      </a>
    )
  }
  if (field.type == "TEXT" || field.type == "TEXTAREA") {
    return (
      <div class="form-group text-left">
        <label class="input-label">

          <small class="mr-1">{field.settings.index}</small>
          <b class={parentName != "" ? "font-weight-normal" : ""}>{field.settings.title}</b>
        </label>
        {field.settings.subtitle ? <label class="input-label"><i style={{ color: "#aaa" }}>{field.settings.subtitle}</i></label> : null}
        {
          [...Array(fieldCount).keys()].map(i => {
            if (field.type == "TEXT")
              return <Field type="text"
                name={parentName + field.name + (field.isMultiple ? "[" + i + "]" : "")}
                class="form-control mt-2"></Field>

            if (field.type == "TEXTAREA")
              return <Field as="textarea"
                rows={4}
                name={parentName + field.name + (field.isMultiple ? "[" + i + "]" : "")}
                class="form-control mt-2"></Field>

          }

          )
        }
        {
          field.isMultiple ?
            <div class="text-left mb-1">
              <div class="d-flex">
                <button type="button" class="btn btn-link pl-0"
                  onClick={() => {
                    multipleData[parentName + field.name] = fieldCount + 1
                    setMultipleData(multipleData)
                    setFieldValue("")
                  }}
                  style={{ color: "#007473" }}>
                  <i class="fas fa-plus"></i>&nbsp;Agregar &quot;{field.settings.title}&quot;
                </button>
                {fieldCount > 1 ?
                  <button type="button" class="btn btn-link pl-0"
                    onClick={() => {
                      multipleData[parentName + field.name] = fieldCount - 1
                      setMultipleData(multipleData)
                      setFieldValue("")
                    }}
                    style={{ color: "#555" }}>
                    <i class="fas fa-minus"></i>&nbsp;Quitar registro
                  </button> : null}
              </div>
            </div> : null
        }
      </div>
    )
  }

  if (field.type == "MULTIFIELD") {
    return (
      <div class="form-group text-left">
        <label class="input-label mb-3">
          <small class="mr-1">{field.settings.index}</small>
          <b>{field.settings.title}</b>
          {field.settings.subtitle ? <label class="input-label"><i style={{ color: "#aaa" }}>{field.settings.subtitle}</i></label> : null}
        </label>

        {
          [...Array(fieldCount).keys()].map((i, idx) =>
            <div key={idx} class="d-flex w-100 ml-6">

              <div class="d-flex w-100">
                {field.isMultiple ?
                  <label class="mr-2 mt-1">
                    {depth == 0 ?
                      <div class="rounded-circle d-inline-block d-flex align-items-center justify-content-center mr-2"
                        style={{ background: "#00454d", color: "white", width: "1.5rem", height: "1.5rem" }}>{idx + 1}
                      </div> :
                      <div class="rounded-circle d-inline-block d-flex align-items-center justify-content-center"
                        style={{ background: "#e0e0e0", color: "#00454d", width: "1.2rem", height: "1.2rem" }}>{idx + 1}
                      </div>
                    }
                  </label>
                  : null}


                <div style={{ width: `calc(100% - ${(depth * 20 + 70)}px)` }}>
                  {field.subFields.map((subField, idx) => {
                    return (
                      <div
                        key={idx}
                        class="pl-0 pr-2"
                        style={{
                          marginLeft: (depth * 20) + "px",
                          width: "100%"
                        }}
                      >
                        {getField(subField, multipleData, setMultipleData, setFieldValue,
                          parentName + field.name + (field.isMultiple ? "[" + i + "]" : "") + ".", depth + 1,
                          fieldCount > 1 ? idx + 1 : 0)}
                      </div>
                    )
                  })}
                  {fieldCount > 1 ? <hr class="mt-5 mb-7"
                    style={{ position: "relative", border: "none", height: "0.5px", width: "100%", background: "#ddd" }} />
                    : null}
                </div>
              </div>

            </div>)
        }

        {
          field.isMultiple ?
            <div class="text-left mb-2" style={{ marginLeft: ((depth + 1) * 20) + "px" }}>
              <button type="button" class="btn btn-link pl-0"
                onClick={() => {
                  multipleData[parentName + field.name] = fieldCount + 1
                  setMultipleData(multipleData)
                  setFieldValue("")
                }}
                style={{ color: "#007473" }}>
                <i class="fas fa-plus"></i>&nbsp;Agregar &quot;{field.settings.title}&quot;
              </button>
              {fieldCount > 1 ?
                <button type="button" class="btn btn-link pl-0"
                  onClick={() => {
                    multipleData[parentName + field.name] = fieldCount - 1
                    setMultipleData(multipleData)
                    setFieldValue("")
                  }}
                  style={{ color: "#555" }}>
                  <i class="fas fa-minus"></i>&nbsp;Quitar registro
                </button> : null}
            </div> : null
        }
        <hr class="mt-3 mb-7" style={{ position: "relative", border: "none", height: "0.5px", width: "100%", background: "#ddd" }} />
      </div>
    )
  }
}


const FormView = ({ 
  formId,
  responseId,
  responseData,
  formData = {}, 
  initialValues = {}, 
  responses = [],
  fieldsData = [], 
  multipleData = {}, 
  isShareShown = false,
  isResponsesShown = false,
  isEdit, 
  isAdmin,
  isOwner,
  isInvitation,
  userType,
  setMultipleData, 
  setIsShareShown,
  setResponseId,
  setResponseData,
  error, 
  setSubmitType, 
  onSubmit,
  onSelectResponse
}) =>
  <>
    <main id="content">
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          marginRight: "20px",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
        }}
      >
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1921 273"
          >
            <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
          </svg>
        </figure>
      </div>

      <div class="container py-5 py-sm-7">

        <div class="row justify-content-center mt-4">
          <div class="col-md-10">
            <div class="card card-lg mb-5">
              <div class="card-body">

                {
                  !isResponsesShown && <>

                    <div class="text-center">
                      <div class="mb-2 mt-5">
                        <p class="display-4" style={{ fontSize: 35 }}>{formData.settings.title}</p>
                        {formData.settings.subtitle ? <label class="h3 mt-n7"><i style={{ color: "#aaa" }}>{formData.settings.subtitle}</i></label> : null}
                      </div>
                      {formData.settings.description ?
                        <div
                          style={{
                            color: "#41464b",
                            backgroundColor: "#f0f0f0",
                            borderColor: "#d3d6d8",
                            textAlign: "justify",
                          }}
                          class="alert mb-6 ml-6 mr-6 mt-3"
                          role="alert"
                        >
                          <br />
                          <p style={{ color: "black", fontStyle: "italic" }}>
                            {formData.settings.description}
                          </p>
                        </div> : null
                      }
                    </div>

                    <div class="row justify-content-center">
                      <div class="col-lg-11 pr-6">
                        <div className="d-flex justify-content-end w-100">
                          {
                            function(){
                              
                              let buttonClassName = "";
                              let iconClassName = "";

                              if(userType == "ADMIN" || userType == "OWNER") {
                                if(responseData?.invitations?.length > 0) {
                                  buttonClassName = "btn btn-warning w-25 mt-0";
                                  iconClassName = "fas fa-share-alt mr-2";
                                } 
                                else {
                                  buttonClassName = "btn btn-primary w-25 mt-0";
                                  iconClassName = "fas fa-share-alt text-white mr-2";
                                }
                              }
                              else {
                                buttonClassName = "btn btn-primary-outline btn-primary w-25 mt-0";
                                iconClassName = "";
                              }

                              return <button
                                type="button"
                                class={buttonClassName}
                                onClick={() => setIsShareShown(!isShareShown)}
                              >
                                <i class={iconClassName} />
                                {
                                  function(){
                                    if((userType == "ADMIN" && isEdit) || userType == "OWNER") {
                                      return responseData?.invitations?.length > 0 
                                        ? "Compartido" 
                                        : "Compartir"
                                    }
                                    return <>Compartido por: {responseData?.user?.first_name} {responseData?.user?.last_name}</>
                                  }()
                                }
                              </button>

                            }()
                          }
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="col-lg-11 text-center pl-6 pr-6 pb-8" style={{ backgroundColor: "white" }}>
                        <Formik
                          initialValues={initialValues}
                          onSubmit={onSubmit}>
                          {({ values, setFieldValue }) =>
                            <Form>
                              <div class="mb-3">
                                {
                                  (() => {
                                    let res = []
                                    let subRes = []
                                    let currentSection = null
                                    for (let field of fieldsData) {
                                      let component = getField(field, multipleData, setMultipleData, setFieldValue);
                                      if (field.type == "SECTION") {
                                        if (currentSection != null) {
                                          res.push(<div id={"section-" + currentSection} class="collapse">{subRes}</div>)
                                          subRes = []
                                        }
                                        res.push(component);
                                        currentSection = field.settings.index;
                                      } else {
                                        if (currentSection == null)
                                          res.push(component);
                                        else
                                          subRes.push(component);
                                      }
                                    }
                                    if (currentSection != null)
                                      res.push(<div id={"section-" + currentSection} class="collapse">{subRes}</div>)
                                    return res;
                                  })()
                                }
                              </div>
                              {error ? <div class="alert alert-soft-danger">{error}</div> : null}
                              {!isEdit || responseData?.type == "DRAFT" ?
                                <div class="d-flex flex-row-reverse justify-content-center pt-5">
                                  {
                                    (!isAdmin || isOwner) && 
                                      <button type="submit" onClick={() => setSubmitType("FINAL")} class="btn btn-block btn-primary w-25 mt-0 ml-2">Enviar</button>
                                  }
                                  <button type="submit" onClick={() => setSubmitType("DRAFT")} class="btn btn-block btn-secondary w-25  mt-0">Guardar borrador</button>
                                </div> :
                                <div class="d-flex flex-row-reverse justify-content-center pt-5">
                                  <button type="submit" onClick={() => setSubmitType("EDIT")} c class="btn btn-block btn-primary w-75 mt-0 ml-2">Editar</button>
                                </div>
                              }
                            </Form>}
                        </Formik>
                      </div>
                    </div>

                  </>
                }

                {
                  isResponsesShown &&
                    <div class="row justify-content-center">
                      <div class="col-lg-11 text-center pl-6 pr-6 pb-8" style={{ backgroundColor: "white" }}>
                        
                        <div class="d-flex justify-content-start w-100">
                          <h4 class="mb-5">Formularios</h4>
                        </div>

                        <div class="table-responsive">
                          <table class="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Usuario</th>
                                <th>Acciones</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                function(){
                                  let res = []
                                  let invitations = responses.filter(response => response.invitation)
                                  for(let i = 0; i < invitations.length; i++){
                                    let response = invitations[i]
                                    res.push(
                                      <tr>
                                        <td>{i + 1}</td>
                                        <td>{response.user.first_name} {response.user.last_name} (invitación)</td>
                                        <td>
                                          <button 
                                            type="button"
                                            class="btn btn-primary"
                                            onClick={() => onSelectResponse(response)}
                                          >
                                            Editar
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  }
                                  return res
                                }()
                              }
                            </tbody>
                          </table>
                        </div>

                        {
                          function(){
                            let userResponse = responses.filter(response => !response.invitation)
                            return <button 
                              type="button"
                              class="btn btn-primary w-100 mt-5"
                              onClick={() => onSelectResponse(userResponse[0])}
                            >
                              Continuar con mi formulario
                            </button>
                          }()
                        }

                      </div>
                    </div>
                }

              </div>
            </div>
          </div>
        </div>

      </div>
    </main>

    <ScriptTag
      content={`
        $(document).ready(function() {
          $("#start, #end").on("click", function(e) {
            e.preventDefault()
            $(this).prop("disabled", !$(this).prop("disabled"))
            $(this).siblings().prop("disabled", !$(this).siblings().prop("disabled"))
          })
        })
      `}
    />

    {
      isShareShown &&
        <Modal
          formId={formId}
          responseData={responseData}
          responseId={responseId}
          setResponseId={setResponseId}
          setResponseData={setResponseData}
          onClose={() => setIsShareShown(false)}
          component={Share}
        />
    }

  </>

FormView.propTypes = {
  error: PropTypes.string,
  onSubmit: PropTypes.func
};

export default FormView;