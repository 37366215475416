import React, { useState } from "react";
import PropTypes from 'prop-types';
import { usePost } from "seed/api";

function Share({ 
  formId, 
  responseData, 
  responseId, 
  setResponseId, 
  setResponseData,
  onClose 
}) {

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);

  const showMessage = (type, body, callback) => {
    setMessage({ type, body });
    setTimeout(() => {
      setMessage(null);
      if(callback) callback();
    }, 2000);
  }

  const [cShare, qShare] = usePost("/responses/share", {
    onCompleted: (data) => {

      setEmail("");
      setResponseData({
        ...responseData,
        invitations: [...responseData.invitations, data]
      });

      showMessage("success", "¡Compartido con éxito!", () => {
        setResponseId(data.response_id);
        setMessage(null);
      });

    },
    onError: (error) => {
      if(error.status == 409)
        showMessage("danger", "¡Ya compartiste este formulario con este usuario!");
      else
        showMessage("danger", "¡Algo salió mal!");
    }
  });

  const [cUnshare, qUnshare] = usePost("/responses/unshare", {
    onCompleted: (data) => {
      showMessage("success", "¡Eliminado con éxito!");
      setResponseData({
        ...responseData,
        invitations: responseData.invitations.filter(i => i.id != data.id)
      });
    },
    onError: (error) => {
      showMessage("danger", "¡Algo salió mal!");
    }
  });

  const onShare = () => 
    cShare({ email, form_id: formId, response_id: responseId });

  const onUnshare = (invitation) =>
    cUnshare({ invitation_id: invitation.id });

  return <div className="card card-body">

    <h5 className="mb-2">
      {
        responseData?.invitations?.length > 0
          ? "Compartido con: "
          : "Compartir"
      }
    </h5>

    <table className="table table-sm table-borderless mb-2">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" className="text-center"></th>
        </tr>
      </thead>
      <tbody>
        {
          responseData?.invitations?.map(invitation =>
            <tr key={invitation.email}>
              <td className="text-left align-middle px-2">
                {invitation.email}
              </td>
              <td className="text-right px-2">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => onUnshare(invitation)}
                >
                  <i className="fa fa-trash text-white"></i>
                </button>
              </td>
            </tr>
          )
        }
      </tbody>
    </table>

    <input 
      className="form-control mb-2 mt-2"
      type="email" 
      placeholder="Correo" 
      value={email}
      onChange={e => setEmail(e.target.value)}
    />
    
    { message && <div className={`alert alert-${message.type}`}>{message.body}</div> }

    <button
      className="btn btn-primary"
      onClick={onShare}
    >
      Compartir
    </button>

  </div>;
}

Share.propTypes = {
  history: PropTypes.object.isRequired
};

export default Share;